var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-carousel-item',{style:({
    backgroundImage:
      'url(' +
      ('img' in _vm.slide_item
        ? require('@/assets/slide/' + _vm.slide_item.img)
        : '') +
      ')'
  })},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"headline text-center white--text"},[_vm._v(" "+_vm._s(_vm.slide_item.title)+" ")]),_c('div',{staticClass:"body-1 text-center px-12 px-lg-24 px-xl-36 white--text"},[_vm._v(" "+_vm._s(_vm.slide_item.desc)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }